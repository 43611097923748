import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['button'];
  static values = {
    title: String,
    text: String,
    cancel: String
   }

  connect() {
    this.confirmed = false;
    this.buttonTarget.dataset.action = 'error-button#click';
  }

  click(event) {
    event.preventDefault();

    Swal.fire({
      title: this.titleValue,
      text: this.textValue,
      icon: 'error',
      showCancelButton: true,
      showConfirmButton: false,
      reverseButtons: true,
      html: `${this.textValue} `,
      cancelButtonText: this.cancelValue
    })
  }
}