import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['select'];
  static values = { options: Array, selecteds: Array };

  connect() {
    var formatName = function(item) {
      return ((item.first_name || '') + ' ' + (item.last_name || '')).trim();
    };

    new TomSelect(this.selectTarget, {
      valueField: 'id',
      labelField: ['first_name', 'last_name'],
      searchField: ['first_name', 'last_name'],
      options: this.optionsValue,
      items: this.selectedsValue,
      plugins: ['remove_button'],
      render: {
        option: function (item, escape) {
          var full_name = formatName(item)
          return (
            '<div class="flex flex-row bg-black">' +
              '<span class="first_name">' + escape(full_name) + '</span>' +
            '</div>'
          );
        },
        item: function (item, escape) {
          var full_name = formatName(item)
          return (
            '<div><div class="flex flex-row">' +
              '<span class="first_name">' + escape(full_name) + '</span>' +
            '</div></div>'
          )
        }
      }
    })
  }
}