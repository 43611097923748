import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2';

const i = (key) => {
  const TEXT = {
    title: 'Are you sure?',
    text: 'This action is irreversible.',
    cancel: 'Cancel',
    confirm: 'Ok',
  };
  return TEXT[key];
};

export default class extends Controller {
  static targets = ['button'];
  static values = {
    title: String,
    text: String,
    cancel: String,
    confirm: String,
   }

  connect() {
    this.confirmed = false;
    this.buttonTarget.dataset.action = 'confirm-button#click';
  }

  click(event) {
    if (this.confirmed !== false) return; 
    event.preventDefault();
    const that = this;

    Swal.fire({
      title: this.titleValue,
      text: this.textValue,
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      html: `${this.textValue} `,
      buttons: {
        cancel: this.cancelValue,
        confirm: this.confirmValue,
      },
    }).then((result) => {
      if (!result.isConfirmed) return;

      that.confirmed = true;
      that.buttonTarget.click();
      setTimeout(() => (that.confirmed = false), 500);
      }
    );
  }
}