// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
//
import "@simonwep/pickr/dist/themes/nano.min.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "sweetalert2/src/sweetalert2.scss";
import "virtual:windi-base.css";
import "virtual:windi-components.css";
import "virtual:windi-utilities.css";
import "~/stylesheets/application.scss";
import "~/stylesheets/plugins/flatpickr.scss";

import "@hotwired/turbo-rails";
import "~/plugins/money.js";

import "~/plugins/action_text.js";

import "@oddcamp/cocoon-vanilla-js";

import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import Chart from 'stimulus-chartjs'

const application = Application.start();
const controllers = import.meta.globEager("../**/*_controller.js");
registerControllers(application, controllers);
application.register('chart', Chart)
const app = Application.start();

import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

import "vanilla-icon-picker/dist/themes/default.min.css";
