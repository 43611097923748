import Pickr from "@simonwep/pickr";

window.buttonSettingsController = function (buttonSetting) {
  console.log(buttonSetting);
  return {
    ...buttonSetting,
    pickrbackgroundColor: null,
    pickrtextColor: null,
    activeTab: window.location.href.includes("button_settings") ? "style" : "content",
    init() {
      this.bindCustomColorInput(this.$el.querySelector(".background_color [type=color]"));
      this.bindCustomColorInput(this.$el.querySelector(".text_color [type=color]"));
      if (this.iconButton || this.tileButton) this.iconAlignment = null;
      if (this.iconButton !== undefined || this.tileButton !== undefined) {
        const scope = this;
        this.$watch("tileButton", (value) => {
          if (!value) return;

          scope.iconAlignment = null;
          scope.iconButton = null;
        });
        this.$watch("iconButton", (value) => {
          if (!value) return;

          scope.iconAlignment = null;
          scope.tileButton = null;
        });
        this.$watch("iconAlignment", (value) => {
          if (!value) return;

          scope.iconButton = null;
          scope.tileButton = null;
        });
      }
    },
    bindCustomColorInput(input) {
      const scope = this;
      this[`pickr${input.dataset.prop}`] = Pickr.create({
        el: input,
        theme: "nano",
        default: input.value,
        components: {
          preview: true,
          opacity: false,
          hue: true,
          interaction: {
            hex: false,
            rgba: false,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            save: false,
          },
        },
      }).on("change", (color) => {
        scope[input.dataset.prop] = color.toHEXA().toString();
        scope.$el.querySelector(
          `[data-color-preview=${input.dataset.prop}]`
        ).style.backgroundColor = color.toHEXA().toString();
      });
    },
    openPickr(prop) {
      this[`pickr${prop}`].show();
    },
  };
};
