import EditorJS from "@editorjs/editorjs";
import { Controller } from "@hotwired/stimulus";

import CodeTool from "@editorjs/code";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";

// Connects to data-controller="inputs--editor"
export default class extends Controller {
  static targets = ["editor", "hidden"];

  csrfToken() {
    const metaTag = document.querySelector("meta[name='csrf-token']");
    console.log(metaTag);
    return metaTag ? metaTag.content : "";
  }

  connect() {
    console.log("Hello, Stimulus!", this.element);

    const initialContent = this.getInitialContent();

    this.contentEditor = new EditorJS({
      holder: this.editorTarget,
      data: initialContent,
      tools: {
        header: {
          class: Header,
          config: {
            levels: [1, 2, 3],
          },
        },
        list: {
          class: List,
        },
        paragraph: {
          class: Paragraph,
          config: {
            inlineToolbar: true,
          },
        },
        code: CodeTool,
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: location.pathname.replace("/edit", "/upload_image"),
            },
            additionalRequestHeaders: {
              "X-CSRF-Token": this.csrfToken(),
            },
          },
        },
      },
    });

    this.element.addEventListener("submit", this.saveEditorData.bind(this));
  }

  getInitialContent() {
    const hiddenContentField = this.hiddenTarget;
    if (hiddenContentField && hiddenContentField.value) {
      return JSON.parse(hiddenContentField.value);
    }
    return {};
  }

  async saveEditorData(event) {
    event.preventDefault();

    const outputData = await this.contentEditor.save();
    const articleForm = this.element;

    const hiddenInput = this.hiddenTarget;

    hiddenInput.value = JSON.stringify(outputData);
    articleForm.submit();
  }
}
