import Tagify from "@yaireo/tagify";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "remove"];

  connect() {
    var tagify = new Tagify(this.inputTarget, {
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value).join(","),
    });

    // this.removeTarget.addEventListener('click', tagify.removeAllTags.bind(tagify))
  }
}
