import Pickr from "@simonwep/pickr";

window.columnColorsController = function (column) {
  return {
    ...column,
    pickrbackgroundColor: null,
    pickrtextColor: null,
    init() {
      this.bindCustomColorInput(this.$el.querySelector(".background_color [type=color]"));
      this.bindCustomColorInput(this.$el.querySelector(".text_color [type=color]"));
    },
    bindCustomColorInput(input) {
      const scope = this;

      this[`pickr${input.dataset.prop}`] = Pickr.create({
        el: input,
        theme: "nano",
        default: input.value,
        components: {
          preview: true,
          opacity: false,
          hue: true,
          interaction: {
            hex: false,
            rgba: false,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            save: false,
          },
        },
      }).on("change", (color) => {
        scope[input.dataset.prop] = color.toHEXA().toString();
        scope.$el.querySelector(
          `[data-color-preview=${input.dataset.prop}]`
        ).style.backgroundColor = color.toHEXA().toString();
      });
    },
    changePreview(value, field) {
      if (field === 'text_color') {
        return this.$el.querySelector(".input_preview").style.color = value;
      };
      this.$el.querySelector(".input_preview").style.backgroundColor = value;
    },
    openPickr(prop) {
      this[`pickr${prop}`].show();
    },
  };
};