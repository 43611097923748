import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "preview", "button", "trash", "remove", "placeholder"];
  static values = {
    image: String,
  };

  connect() {
    const trashIcon = this.trashTarget;
    this.previewTarget.src = this.imageValue;
    this.hideImagePreview()
    this.handlePreview(this.imageValue)

    const scope = this;

    trashIcon.addEventListener("click", function (e) {
      scope.setRemoveImageInput("true");
      e.preventDefault();
      scope.hideImagePreview();
    });
  }

  hideImagePreview() {
    const useImageAsBackground = document.getElementsByClassName(`${this.inputTarget.id}-image-as-background`)

    if (this.previewTarget.getAttribute('src')) {
      this.previewTarget.classList.remove('hidden'), this.placeholderTarget.classList.add('hidden');

      if (useImageAsBackground[0] === undefined) return
      for (var i = 0; i !== useImageAsBackground.length; i++) {
        useImageAsBackground[i].classList.remove('hidden')
      }
      return
    }

    this.previewTarget.classList.add('hidden'), this.placeholderTarget.classList.remove('hidden')

    if (useImageAsBackground[0] === undefined) return
    for (var i = 0; i !== useImageAsBackground.length; i++) {
      useImageAsBackground[i].classList.add('hidden')
    }
  };

  chooseFile() {
    this.inputTarget.click();
  }

  setRemoveImageInput(value) {
    this.removeTarget.defaultValue = this.removeTarget.value = value;
    this.previewTarget.src = '';
    this.hideImagePreview();
  }

  fileChosen(event) {
    const target = this.previewTarget;
    this.fileToDataUrl(event, (src) => (target.src = src, this.handlePreview(src)));
  }

  fileToDataUrl(event, callback) {
    if (!event.target.files.length) return;

    let file = event.target.files[0],
      reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (e) => callback(e.target.result);
  }

  handlePreview(new_src) {
    this.hideImagePreview();

    const useImageAsBackground = document.getElementsByClassName(`${this.inputTarget.id}-image-as-background`)
    if (useImageAsBackground[0] === undefined) return

    for (var i = 0; i !== useImageAsBackground.length; i++) {
      useImageAsBackground[i].src = new_src
    }
  }
}
