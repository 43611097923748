import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

const i = (key) => {
  const TEXT = {
    title: "Are you sure?",
    text: "This action is irreversible.",
    cancel: "Cancel",
    confirm: "Ok",
  };
  return TEXT[key];
};

export default class extends Controller {
  static targets = ["button"];
  static values = {
    title: String,
    text: String,
    cancel: String,
    firstUrl: String,
    secondUrl: String,
    firstButtonText: String,
    secondButtonText: String,
  };

  connect() {
    this.confirmed = false;
    this.buttonTarget.dataset.action = "choose-button#click";
  }

  click(event) {
    if (this.confirmed !== false) return;
    event.preventDefault();
    const that = this;

    Swal.fire({
      title: this.titleValue,
      icon: "info",
      html:
        `${this.textValue} <br><br><br>` +
        `<div class="flex space-x-4 justify-center">` +
        `<form method="post" action="${this.firstUrlValue}">` +
        `<input type="hidden" name="_method" value="delete" autocomplete="off">` +
        `<input type="hidden" name="authenticity_token" value="${
          document.querySelector("meta[name=csrf-token]").content
        }"/>` +
        `<button type="submit" class="btn bg-primary-500 hover:bg-primary-600 text-white">${this.firstButtonTextValue}</button>` +
        `</form>` +
        `<form method="post" action="${this.secondUrlValue}">` +
        `<input type="hidden" name="_method" value="delete" autocomplete="off">` +
        `<input type="hidden" name="authenticity_token" value="${
          document.querySelector("meta[name=csrf-token]").content
        }"/>` +
        `<input type="hidden" name="option" value="only_from_group" autocomplete="off">` +
        `<button type="submit" class="btn bg-primary-500 hover:bg-primary-600 text-white">${this.secondButtonTextValue}</button>` +
        `</form>` +
        `</div>`,
      showCancelButton: true,
      showConfirmButton: false,
    });
  }
}
